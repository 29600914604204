import type React from "react";

import classNames from "classnames";

import { Field } from "./Field";

// warning: don't use the *selected* attribute in child options if the parent select has a value or defaultValue set!

const Select = ({
  className,
  label,
  shrink,
  ...rest
}: React.SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string;
  shrink?: boolean;
}) => {
  return (
    <Field label={label}>
      <select
        className={classNames(
          className,
          { "w-full md:w-auto": !shrink },
          "block px-3 py-2.5 rounded bg-[#F0F0F0] border-[#DFE2E4] border shadow-inner", // default classes
        )}
        {...rest}
      />
    </Field>
  );
};

export default Select;
